import React from 'react';
import Utils from '../../utils/utils';
import useMedia from '../../utils/useMedia';

const HomepageCoverSlider = () => {
    const isMobile = useMedia('(max-width: 480px)');
    const pathImage = isMobile
        ? 'mobile-communication-search-background.jpg'
        : 'communication-search-background.jpg';

    const containerStyle = {
        backgroundImage: `url(${Utils.getCloudfrontDomain()}/images/website/home_page/${pathImage})`,
        backgroundSize: 'cover'
    };

    return <div className="homepage-pictures" style={containerStyle} />;
};

export default HomepageCoverSlider;
